<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div>
        <a-button
          type="file-pdf"
          @click="generatePdf"
          :loading="printLoading"
          :disabled="printLoading"
          style="margin-bottom: 20px; float: right"
        >
          <a-icon v-if="!printLoading" type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
        <json-csv
          class="ant-btn"
          style="margin-right: 20px; margin-bottom: 20px; float: right"
          :fields="fields"
          :data="csvToExport"
          :name="'listeReglement.csv'"
          ><a-icon
            class="anticon mt-2"
            style="color: green"
            type="file-excel"
          /><span>CSV</span></json-csv
        >
        <a-button type="user-add" @click="link">
          <a-icon type="user-add" @click="link" />{{
            $t("liste.ajouterDossier")
          }}
        </a-button>
        <a-range-picker
          style="margin-left: 20px"
          :placeholder="[$t('paiement.dateDebut'), $t('paiement.dateFin')]"
          format="DD-MM-YYYY"
          v-model="dateRangeData"
          :mode="mode"
          @change="
            (val) => {
              handleDateRange(val);
            }
          "
        />
      </div>
      <div>
        <a-table
          @change="tableChanged"
          rowKey="_id"
          :pagination="true"
          :data-source="data"
          :columns="columns"
          :loading="tableLoading"
          :scroll="{ x: 'max-content' }"
        >
          <template slot="paymentOnWhat" slot-scope="text, record">
            <div class="d-inline">
              <a-tag color="blue" v-if="record.info.onInscription">{{
                $t("recette.inscription")
              }}</a-tag>
              <div v-if="record.info.months">
                <a-tag
                  color="blue"
                  v-for="(value, key) in record.info.months"
                  :key="key"
                  >{{ frMonths(key) }}</a-tag
                >
              </div>
            </div>
          </template>
          <a-tag color="#2db7f5" slot="userName" slot-scope="text">
            {{ text ? text : "--" }}
          </a-tag>
          <template slot="modePaiement" slot-scope="text, record">
            <a-button
              icon="search"
              type="primary"
              block
              v-if="record.cheque"
              @click="chequeDetailsModal(record)"
            >
              {{
                text == "espéce"
                  ? $t("recette.espece")
                  : text == "chèque"
                  ? $t("recette.cheque")
                  : text == "Chèque et espéce"
                  ? $t("recette.chequeEtEspece")
                  : text
              }}
            </a-button>
            <div v-else>
              {{
                text == "espéce"
                  ? $t("recette.espece")
                  : text == "chèque"
                  ? $t("recette.cheque")
                  : text == "Chèque et espéce"
                  ? $t("recette.chequeEtEspece")
                  : text
              }}
            </div>
          </template>
          <template slot="date" slot-scope="text">
            {{ moment(text).format("DD/MM/YYYY") }}
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <a-button
            slot="annulation"
            type="danger"
            :loading="loadingAnnulation"
            :disabled="loadingAnnulation"
            slot-scope="text, record"
            @click="
              () => {
                selectedRecord = record;
                visibleCancelModal = true;
              }
            "
          >
            <a-icon type="delete" href="javascript:;" />{{
              $t("recette.annulation")
            }}
          </a-button>

          <a-button
            slot="print"
            type="primary"
            slot-scope="text, record"
            @click="() => print(record)"
          >
            <a-icon type="file-pdf" />{{ $t("recette.imprimer") }}
          </a-button>
        </a-table>
      </div>
    </div>
    <a-modal
      v-model="chequeVisible"
      :title="$t('recette.detailsCheque')"
      @ok="handleOk"
    >
      <div v-if="activeCheque.montantCheque || activeCheque.montantEspece">
        <a-descriptions>
          <a-descriptions-item span="2">
            <template v-slot:label>
              <span class="ant-descriptions-item-label"
                >{{ $t("recette.montantCheque") }} :</span
              >
            </template>
            {{ activeCheque.montantCheque }}
          </a-descriptions-item>
          <a-descriptions-item span="3">
            <template v-slot:label>
              <span class="ant-descriptions-item-label"
                >{{ $t("recette.montantEspece") }} :</span
              >
            </template>
            {{ activeCheque.montantEspece }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider></a-divider>
      </div>
      <a-descriptions>
        <a-descriptions-item span="2">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.numero")
            }}</span>
          </template>
          {{ activeCheque.num }}
        </a-descriptions-item>
        <a-descriptions-item span="3">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.titulaire")
            }}</span>
          </template>
          {{ activeCheque.titulaire }}
        </a-descriptions-item>
        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.banque")
            }}</span>
          </template>
          <a-tag color="blue">
            {{ activeCheque.bank }}
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.dateEcheance")
            }}</span>
          </template>
          {{ moment(activeCheque.dateEchance).format("DD/MM/YYYY") }}
        </a-descriptions-item>
      </a-descriptions>
      <a-alert
        :message="moment(activeCheque.dateEchance).fromNow()"
        type="info"
        :close-text="$t('recette.fermer')"
      />
    </a-modal>
    <a-modal
      v-model="visibleCancelModal"
      :title="$t('paiement.annulation')"
      @ok="
        () => {
          annulation(selectedRecord);
          visibleCancelModal = false;
        }
      "
    >
      <a-label>{{ $t("recette.type") }} : </a-label>
      <div class="text-center">
        <span class="ml-2 mr-2">{{ $t("paiement.avoir") }}</span>
        <a-switch
          :class="cancelType == 'Avoir' ? 'yellow-switch' : 'red-switch'"
          default-checked
          @change="
            (val) => {
              cancelType = val ? 'Annulation' : 'Avoir';
              visibleCancelModal = true;
            }
          "
        />
        <span class="ml-2 mr-2">{{ $t("paiement.annulation") }}</span>
      </div>
      <a-label>{{ $t("paiement.note") }} : </a-label>
      <a-textarea
        v-model="cancelNote"
        :placeholder="$t('paiement.note')"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import JsPDF from "jspdf";
import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import apiClient from "@/services/axios";
import Vue from "vue";
import VueRouter from "vue-router";
import moment from "moment";
import JsonCsv from "vue-json-csv";
Vue.use(VueRouter);

export default {
  computed: {
    ...mapState(["settings"]),
    csvToExport() {
      const data = [...this.filtredTable];
      return data.map((item) => {
        let elems = [];
        if (item.info.months)
          for (const key of Object.keys(item.info.months)) {
            elems.push(this.frMonths(key));
          }

        return {
          serial: item.serial,
          nomClient: item.nomClient,
          typeReglement: item.typeReglement,
          paiementSur: elems,
          modePaiement: item.modePaiement,
          montantHT: item.montantHT,
          montantTTC: item.montantTTC,
          datePaiement: moment(item.date).format("DD/MM/YYYY"),
        };
      });
    },
  },
  components: {
    JsonCsv,
  },
  async created() {
    await apiClient
      .post("/parents/filter", {
        query: {},
        aggregation: {
          $project: {
            father: 1,
            mother: 1,
          },
        },
      })
      .then((res) => (this.parents = res.data))
      .catch();
    await this.getActivityReceipt();
    await this.getActivityBill();
    await this.getClassroomReceipt();
    await this.getClassroomBills();
    this.cacheData = this.data.map((item) => ({ ...item }));
    this.rowData = this.data.map((item) => ({ ...item }));
    this.tableLoading = false;
    this.filtredTable = this.data.map((item) => {
      item.montantTTC = item.montantTTC.toFixed(2);
      item.montantHT = item.montantHT.toFixed(2);
      return item;
    });
    this.filtredTableCache = this.filtredTable;
  },

  data() {
    return {
      dateRangeData: ["", ""],
      mode: ["date", "date"],
      fields: [
        "serial",
        "nomClient",
        "typeReglement",
        "paiementSur",
        "modePaiement",
        "montantHT",
        "montantTTC",
        "datePaiement",
      ],
      editingKey: "",
      FISCAL: parseFloat(process.env.VUE_APP_FISCAL),
      TVA: parseFloat(process.env.VUE_APP_TVA),
      rowData: [],
      selectedRecord: {},
      visibleCancelModal: false,
      cancelType: "Annulation",
      cancelNote: "",
      tableLoading: true,
      parents: [],
      visibleParentFile: false,
      data: [],
      chequeVisible: false,
      activeCheque: {},
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      indeterminate: true,
      columns: [
        //numéro de série
        {
          title: this.$t("recette.numSerie"),
          dataIndex: "serial",
          key: "serial",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "serial",
          },
          onFilter: (value, record) =>
            record.serial.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.nom"),
          dataIndex: "nomClient",
          key: "nomClient",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nomClient",
          },
          onFilter: (value, record) =>
            record.nomClient.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeReglement"),
          dataIndex: "typeReglement",
          key: "typeReglement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "typeReglement",
          },
          onFilter: (value, record) =>
            record.typeReglement.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.paiementQuoi"),
          dataIndex: "paymentOnWhat",
          key: "paymentOnWhat",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "paymentOnWhat",
          },
          onFilter: (value, record) => {
            let result = false;
            try {
              const { info } = record;
              let elems = [];
              if (info.onInscription) elems.push("inscription");
              if (info.months)
                for (const key of Object.keys(info.months)) {
                  elems.push(this.frMonths(key));
                }
              for (const elem of elems) {
                if (elem.toLowerCase().includes(value.toLowerCase()))
                  result = true;
              }
            } catch {
              result = true;
            }
            return result;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.modePaiement"),
          dataIndex: "modePaiement",
          key: "modePaiement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "modePaiement",
          },
          onFilter: (value, record) => {
            if (record.cheque) {
              return (
                record.modePaiement
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.num.toLowerCase().includes(value.toLowerCase()) ||
                record.cheque.bank
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.titulaire
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.dateEchance
                  .toLowerCase()
                  .includes(value.toLowerCase())
              );
            } else {
              return;
              record.modePaiement.toLowerCase().includes(value.toLowerCase());
            }
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantHT"),
          dataIndex: "montantHT",
          key: "montantHT",
          sorter: (a, b) => a.montantHT - b.montantHT,
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "montantTTC",
          key: "montantTTC",
          sorter: (a, b) => a.montantTTC - b.montantTTC,
        },
        {
          title: this.$t("recette.datePaiement"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            moment(record.date).format("DD/MM/YYYY").includes(value),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.doneBy"),
          dataIndex: "userName",
          key: "userName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "userName",
          },
          onFilter: (value, record) => {
            return record.userName
              ? record.userName.toLowerCase().includes(value.toLowerCase())
              : false;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.impression"),
          dataIndex: "print",
          key: "print",
          fixed: "right",
          scopedSlots: {
            customRender: "print",
          },
        },
        {
          title: this.$t("recette.annulation"),
          dataIndex: "annulation",
          key: "annulation",
          fixed: "right",
          scopedSlots: {
            customRender: "annulation",
          },
        },
      ],
      filtredTable: [],
      loadingAnnulation: false,
      filtredTableCache: [],
      printLoading: false,
    };
  },
  methods: {
    handleDateRange(value) {
      if (value.length == 2) {
        const start = moment(value[0]);
        const end = moment(value[1]);

        this.data = this.rowData.filter((elem) => {
          return moment(elem.date).isBetween(start, end, "days", "[]");
        });

        this.filtredTable = this.filtredTableCache.filter((elem) => {
          return moment(elem.date).isBetween(start, end, "days", "[]");
        });
      } else {
        this.data = this.rowData;
        this.filtredTable = this.filtredTableCache;
      }
    },
    async getActivityReceipt() {
      await apiClient
        .post("/money/activityreceipt/filter", {
          query: { status: { $ne: "cancelled" } },
          aggregation: [
            {
              $lookup: {
                from: "activityfees",
                localField: "activityFees",
                foreignField: "_id",
                as: "activityfees",
              },
            },
            {
              $lookup: {
                from: "students",
                localField: "activityfees.student",
                foreignField: "_id",
                as: "student",
              },
            },
            {
              $lookup: {
                from: "activities",
                localField: "activityfees.activity",
                foreignField: "_id",
                as: "activity",
              },
            },
            {
              $lookup: {
                from: "receipttypes",
                localField: "receiptType",
                foreignField: "_id",
                as: "receiptType",
              },
            },
            {
              $lookup: {
                from: "cheques",
                localField: "cheque",
                foreignField: "_id",
                as: "cheque",
              },
            },
            {
              $project: {
                _id: 1,
                date: 1,
                serial: 1,
                months: 1,
                onBillReduction: 1,
                onInscription: 1,
                student: {
                  firstName: 1,
                  lastName: 1,
                  parent: 1,
                },
                activity: {
                  _id: 1,
                  designation: 1,
                  monthly: 1,
                  inscription: 1,
                },
                activityfees: {
                  _id: 1,
                  reduction: 1,
                  inscription: 1,
                  months: 1,
                },
                receiptType: { name: 1 },
                cheque: { $arrayElemAt: ["$cheque", 0] },
                montantCheque: 1,
                montantEspece: 1,
                userName: 1,
              },
            },
          ],
        })
        .then((res) => {
          res.data.map((elem) => {
            this.data.push(
              this.activityFormater({
                ...elem,
                activity: elem.activity[0],
                activityfees: elem.activityfees[0],
                receiptType: elem.receiptType[0],
                student: elem.student[0],
              })
            );
          });
        })
        .catch();
    },
    async getActivityBill() {
      await apiClient
        .post("/money/activitybill/filter", {
          query: { status: { $ne: "cancelled" } },
          aggregation: [
            {
              $lookup: {
                from: "activityfees",
                localField: "activityFees",
                foreignField: "_id",
                as: "activityfees",
              },
            },
            {
              $lookup: {
                from: "students",
                localField: "activityfees.student",
                foreignField: "_id",
                as: "student",
              },
            },
            {
              $lookup: {
                from: "activities",
                localField: "activityfees.activity",
                foreignField: "_id",
                as: "activity",
              },
            },
            {
              $lookup: {
                from: "receipttypes",
                localField: "receiptType",
                foreignField: "_id",
                as: "receiptType",
              },
            },
            {
              $lookup: {
                from: "cheques",
                localField: "cheque",
                foreignField: "_id",
                as: "cheque",
              },
            },
            {
              $project: {
                _id: 1,
                date: 1,
                serial: 1,
                months: 1,
                onBillReduction: 1,
                onInscription: 1,
                student: {
                  firstName: 1,
                  lastName: 1,
                  parent: 1,
                },
                activity: {
                  _id: 1,
                  designation: 1,
                  monthly: 1,
                  inscription: 1,
                },
                activityfees: {
                  _id: 1,
                  reduction: 1,
                  inscription: 1,
                  months: 1,
                },
                receiptType: { name: 1 },
                cheque: { $arrayElemAt: ["$cheque", 0] },
                montantCheque: 1,
                montantEspece: 1,
                userName: 1,
              },
            },
          ],
        })
        .then((res) => {
          res.data.map((elem) => {
            this.data.push(
              this.activityFormater(
                {
                  ...elem,
                  activity: elem.activity[0],
                  activityfees: elem.activityfees[0],
                  receiptType: elem.receiptType[0],
                  student: elem.student[0],
                },
                true
              )
            );
          });
        })
        .catch();
    },
    async getClassroomReceipt() {
      await apiClient
        .post("/money/classroomreceipt/filter", {
          query: { status: { $ne: "cancelled" } },
          aggregation: [
            {
              $lookup: {
                from: "classroompayments",
                localField: "classRoomPayment",
                foreignField: "_id",
                as: "classRoomPayment",
              },
            },
            {
              $lookup: {
                from: "students",
                localField: "classRoomPayment.student",
                foreignField: "_id",
                as: "student",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoomPayment.classRoom",
                foreignField: "_id",
                as: "classRoom",
              },
            },
            {
              $lookup: {
                from: "receipttypes",
                localField: "receiptType",
                foreignField: "_id",
                as: "receiptType",
              },
            },
            {
              $lookup: {
                from: "cheques",
                localField: "cheque",
                foreignField: "_id",
                as: "cheque",
              },
            },
            {
              $project: {
                _id: 1,
                date: 1,
                serial: 1,
                months: 1,
                onBillReduction: 1,
                onInscription: 1,
                student: {
                  firstName: 1,
                  lastName: 1,
                  parent: 1,
                },
                classRoom: {
                  _id: 1,
                  name: 1,
                  monthly: 1,
                  inscription: 1,
                },
                classRoomPayment: {
                  _id: 1,
                  reduction: 1,
                  inscription: 1,
                  months: 1,
                },
                receiptType: { name: 1 },
                cheque: { $arrayElemAt: ["$cheque", 0] },
                montantCheque: 1,
                montantEspece: 1,
                userName: 1,
              },
            },
          ],
        })
        .then((res) => {
          res.data.map((elem) => {
            if (elem.classRoomPayment[0]) {
              this.data.push(
                this.classRoomPFormater({
                  ...elem,
                  classRoom: elem.classRoom[0],
                  classRoomPayment: elem.classRoomPayment[0],
                  receiptType: elem.receiptType[0],
                  student: elem.student[0],
                })
              );
            }
          });
        })
        .catch();
    },
    async getClassroomBills() {
      await apiClient
        .post("/money/classroombill/filter", {
          query: { status: { $ne: "cancelled" } },
          aggregation: [
            {
              $lookup: {
                from: "classroompayments",
                localField: "classRoomPayment",
                foreignField: "_id",
                as: "classRoomPayment",
              },
            },
            {
              $lookup: {
                from: "students",
                localField: "classRoomPayment.student",
                foreignField: "_id",
                as: "student",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoomPayment.classRoom",
                foreignField: "_id",
                as: "classRoom",
              },
            },
            {
              $lookup: {
                from: "receipttypes",
                localField: "receiptType",
                foreignField: "_id",
                as: "receiptType",
              },
            },
            {
              $lookup: {
                from: "cheques",
                localField: "cheque",
                foreignField: "_id",
                as: "cheque",
              },
            },
            {
              $project: {
                _id: 1,
                date: 1,
                serial: 1,
                months: 1,
                onBillReduction: 1,
                onInscription: 1,
                student: {
                  firstName: 1,
                  lastName: 1,
                  parent: 1,
                },
                classRoom: {
                  _id: 1,
                  name: 1,
                  monthly: 1,
                  inscription: 1,
                },
                classRoomPayment: {
                  _id: 1,
                  reduction: 1,
                  inscription: 1,
                  months: 1,
                },
                receiptType: { name: 1 },
                cheque: { $arrayElemAt: ["$cheque", 0] },
                montantCheque: 1,
                montantEspece: 1,
                userName: 1,
              },
            },
          ],
        })
        .then((res) => {
          res.data.map((elem) => {
            try {
              if (elem.classRoomPayment[0]) {
                this.data.push(
                  this.classRoomPFormater(
                    {
                      ...elem,
                      classRoom: elem.classRoom[0],
                      classRoomPayment: elem.classRoomPayment[0],
                      receiptType: elem.receiptType[0],
                      student: elem.student[0],
                    },
                    true
                  )
                );
              }
            } catch {
              return;
            }
          });
        })
        .catch();
    },
    chequeDetailsModal(record) {
      let recordData = JSON.parse(JSON.stringify(record));

      this.activeCheque = recordData.cheque;
      this.activeCheque.montantCheque = recordData.montantCheque;
      this.activeCheque.montantEspece = recordData.montantEspece;
      this.chequeVisible = true;
    },
    handleOk(e) {
      this.chequeVisible = false;
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
      this.filtredTableCache = extra.currentDataSource;
    },
    moment,
    frMonths(s) {
      const months = {};
      const monthsArr = moment.months();
      for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];

      return months[parseInt(s.split("-")[1])];
    },
    classRoomPFormater(paymentData, bill = false) {
      const onBillReduction = paymentData.onBillReduction
        ? paymentData.onBillReduction
        : 0;
      const reduction = {
        inscription: paymentData.classRoomPayment.reduction.inscription,
        monthly: paymentData.classRoomPayment.reduction.monthly,
      };
      let money = 0;

      if (paymentData.onInscription) {
        const inscriptionFees = parseInt(paymentData.classRoom.inscription);
        money =
          inscriptionFees - (inscriptionFees * reduction.inscription) / 100;
      }
      if (paymentData.months) {
        const nbMonth = Object.keys(paymentData.months).length;
        const monthFees = nbMonth * paymentData.classRoom.monthly;
        money = money + monthFees - (monthFees * reduction.monthly) / 100;
      }
      money = money - (money * onBillReduction) / 100;
      const parent = this.parents.filter(
        (e) => e._id == paymentData.student.parent
      )[0];
      const id = paymentData._id;
      let data = {
        userName: paymentData.userName,

        serial: paymentData.serial,
        onBillReduction: onBillReduction,
        url: bill ? "/bills/classroom/" + id : "/receipt/classroom/" + id,
        _id: id,
        nomClient: parent.father.firstName + " " + parent.father.lastName,
        classRoom: paymentData.classRoom,
        student: paymentData.student,
        typeReglement: "Scolarité/" + paymentData.classRoom.name,
        modePaiement: paymentData.receiptType.name,
        montantHT: money - money * this.TVA,
        montantTTC: money + (bill ? this.FISCAL : 0),
        classRoomPayment: paymentData.classRoomPayment,
        bill: bill,
        cheque: paymentData.cheque ? paymentData.cheque : null,
        montantEspece: paymentData.montantEspece
          ? paymentData.montantEspece
          : null,
        montantCheque: paymentData.montantCheque
          ? paymentData.montantCheque
          : null,
        date: paymentData.date,
        info: {
          onInscription: Boolean(paymentData.onInscription),
          months: paymentData.months,
        },
      };
      return data;
    },
    activityFormater(paymentData, bill = false) {
      const onBillReduction = paymentData.onBillReduction
        ? paymentData.onBillReduction
        : 0;
      const reduction = {
        inscription: paymentData.activityfees.reduction.inscription,
        monthly: paymentData.activityfees.reduction.monthly,
      };
      let money = 0;
      if (paymentData.onInscription) {
        const inscriptionFees = parseInt(paymentData.activity.inscription);
        money =
          inscriptionFees - (inscriptionFees * reduction.inscription) / 100;
      }
      if (paymentData.months) {
        const nbMonth = Object.keys(paymentData.months).length;
        const monthFees = nbMonth * paymentData.activity.monthly;
        money = money + monthFees - (monthFees * reduction.monthly) / 100;
      }
      money = money - (money * onBillReduction) / 100;
      const parent = this.parents.filter(
        (e) => e._id == paymentData.student.parent
      )[0];
      const id = paymentData._id;
      let data = {
        userName: paymentData.userName,

        onBillReduction: onBillReduction,
        serial: paymentData.serial,
        url: bill ? "/bills/activity/" + id : "/receipt/activity/" + id,
        _id: id,
        nomClient: parent.father.firstName + " " + parent.father.lastName,
        typeReglement: "Activité/" + paymentData.activity.designation,
        modePaiement: paymentData.receiptType.name,
        activity: paymentData.activity,
        student: paymentData.student,
        montantHT: money - money * this.TVA,
        montantTTC: money + (bill ? this.FISCAL : 0),
        activityfees: paymentData.activityfees,
        bill: bill,
        cheque: paymentData.cheque ? paymentData.cheque : null,
        montantEspece: paymentData.montantEspece
          ? paymentData.montantEspece
          : null,
        montantCheque: paymentData.montantCheque
          ? paymentData.montantCheque
          : null,
        date: paymentData.date,
        info: {
          onInscription: Boolean(paymentData.onInscription),
          months: paymentData.months,
        },
      };
      return data;
    },
    link() {
      this.$router.push({ path: "reglementCaisse" });
    },
    annulation(record) {
      this.loadingAnnulation = true;

      apiClient
        .post("/reglement/cancelReglement", {
          query: {
            feeID: record.classRoomPayment
              ? record.classRoomPayment._id
              : record.activityfees._id,
            feesCategory: record.classRoomPayment ? "classroom" : "activity",
            receipt: !record.bill,
            payementID: record._id,
            clientName: record.nomClient,
            money: record.montantTTC,
            name: record.typeReglement,
            type: this.cancelType,
            note: this.cancelNote,
          },
        })
        .then((res) => {
          this.tableLoading = true;
          this.data = this.data.filter((elem) => elem._id != record._id);
          this.cacheData = this.cacheData.filter(
            (elem) => elem._id != record._id
          );
          this.rowData = this.rowData.filter((elem) => elem._id != record._id);
          this.$message.success("Le règlement a été annulé");
          this.tableLoading = false;
        })
        .catch((err) => {
          this.$message.error("Une erreur est survenue");
        })
        .finally(() => {
          this.loadingAnnulation = false;
        });
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    print(record) {
      const tva = 0.07;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();

      var titre = record.bill
        ? "Facture N. " + record.serial.split(":")[1]
        : "Recue N. " + record.serial.split("/")[1];
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.text(20, 70, "Nom et Prénom : " + record.nomClient);
      doc.setFontSize(10);
      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (error) {
        console.log(error);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      let onBillReduction = record.onBillReduction
        ? record.onBillReduction / 100
        : 0;
      let reduction = {};
      if (!record.activity)
        reduction = {
          inscription: record.classRoomPayment.reduction.inscription,
          monthly: record.classRoomPayment.reduction.monthly,
        };
      if (record.info.onInscription) {
        arr.push(record.student.lastName + " " + record.student.firstName);
        arr.push("Inscription ");
        arr.push(record.typeReglement);
        //record.activity?arr.push(record.activity.inscription):arr.push(record.classRoom.inscription);
        record.activity
          ? arr.push(
              record.bill === true
                ? record.activity.inscription -
                    record.activity.inscription * tva
                : record.activity.inscription
            )
          : arr.push(
              record.bill
                ? record.classRoom.inscription -
                    record.classRoom.inscription * tva
                : record.classRoom.inscription -
                    (record.classRoom.inscription * reduction.inscription) / 100
            );
        // arr[arr.length - 1] -= arr[arr.length - 1] * onBillReduction;

        array.push(arr);
        arr = [];
      }
      if (record.info.months) {
        Object.keys(record.info.months).forEach((k) => {
          arr.push(record.student.lastName + " " + record.student.firstName);

          let paymentDate = new Date(k);
          let monthName = paymentDate.toLocaleString("fr-FR", {
            month: "long",
          });
          arr.push(monthName);
          arr.push(record.typeReglement);

          record.activity
            ? arr.push(
                record.bill === true
                  ? record.activity.monthly - record.activity.monthly * tva
                  : record.activity.monthly
              )
            : arr.push(
                record.bill
                  ? record.classRoom.monthly - record.classRoom.monthly * tva
                  : record.classRoom.monthly -
                      (record.classRoom.monthly * reduction.monthly) / 100
              );
          //arr[arr.length - 1] -= arr[arr.length - 1] * onBillReduction;

          array.push(arr);
          arr = [];
        });
      }
      let rowData = [
        record.montantHT,
        record.montantTTC - record.montantHT - this.FISCAL,
        this.FISCAL,
        record.montantTTC,
      ];
      //affichier reduction si existe
      if (onBillReduction) {
        arr.push({
          colSpan: 3,
          content: "Réduction",
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        arr.push({
          content: (onBillReduction * 100).toFixed(2) + "%",
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }
      let rowDataText = ["Total HTVA", "TVA", "Timbre", "Total TTC"];
      for (let i = 0; i < 4; i++) {
        if (!record.bill) {
          i = 3;
          rowDataText[i] = "Total";
        }

        arr.push({
          colSpan: 3,
          content: rowDataText[i],
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        arr.push({
          content: parseFloat(rowData[i]).toFixed(2),
          styles: { valign: "middle", halign: "center", fontStyle: "bold" },
        });
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [["Stagiaire", "Frais", "Désignation", "Montant"]],
        body: array,
        foot: [
          [
            {
              colSpan: 4,
              content:
                "Le montant total est " +
                writtenNumber(record.montantTTC, { lang: "fr" }) +
                " DT",
              styles: { halign: "center" },
            },
          ],
        ],
      });
      doc.save(titre + ".pdf");
    },
    generatePdf() {
      this.$gtag.event("Imp listeReglement", {
        event_category: "Impression PDF",
        event_label: "comptabilite:listeReglement",
        value: 1,
      });

      this.printLoading = true;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Liste réglement";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 70, titre);
      doc.setFontSize(10);
      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (error) {
        console.log(error);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      let sommeHT = 0;
      let sommeTTC = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(this.filtredTable[i].nomClient);
        arr.push(this.filtredTable[i].typeReglement);
        arr.push(this.filtredTable[i].modePaiement);
        arr.push(this.filtredTable[i].montantHT);
        sommeHT += parseFloat(this.filtredTable[i].montantHT);
        arr.push(this.filtredTable[i].montantTTC);
        sommeTTC += parseFloat(this.filtredTable[i].montantTTC);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          [
            "Nom du client",
            "Type de réglement",
            "Mode de paiement",
            "Montant HT",
            "Montant TTC",
          ],
        ],
        body: array,
        foot: [
          [
            "",
            "",
            "Total : ",
            parseFloat(sommeHT).toFixed(2),
            parseFloat(sommeTTC).toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      //end page numbering
      doc.save("Liste_Réglement.pdf");

      this.printLoading = false;
    },
  },
};
</script>
<style scoped>
.red-switch {
  background-color: #ff8181 !important;
}

.yellow-switch {
  background-color: #ffd968 !important;
}

.ant-descriptions-item-label {
  font-weight: bold !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
</style>
